@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

:root{
  --font-main: 'Apple';
  --color-primary: #c48e7a;
  --bg-primary: #f2f2f2;
  --bg-secondary: #ffffff;
  --store-text-primary: #000000;
  --store-text-secondary: #252628;
  --product-bg: #f2f2f2;
  --color-primary-dark: #9e6854;
  --color-primary-light: #eab4a0;
  --color-primary-light-2: #FBECE6;
  --color-primary-reverse: #450f00;
  --product-image-height: 22rem;
  --product-border-color: #ffffff;
  --product-enhanced-btn-bg: #ffffff;
  --product-enhanced-btn-color: #c48e7a;
  --products-per-row: 4;
  --footer-bg: #f3f4f6;
  --footer-text-color: #374151;
  --bottom-footer-bg: #F3F4F6;
  --countdown-bg: #dccbbe;
  --countdown-color: #651112;
  --countdown-border-color: #ffffff;
  --gray-border: #CECECE;
}

/* base styles */
* {
  
  margin: 0;
  color: var(--store-text-primary);
  /* background-color: var(--bottom-footer-bg); */
  direction: rtl;
  text-align: center;
  font-family: 'Apple';
		
}
body {
  background: var(--bg-primary);
  margin: 0;
  font-family: "Poppins";
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  /* max-width: 600px; */
  margin: 0 auto;
  border-bottom: 1px solid var(--bg-primary);
}
.navbar h1 {
  color: var(--color-primary-dark);
}
.navbar .links {
  margin-right: auto;
}
.navbar a {
  margin-right: 16px;
  text-decoration: none;
  padding: 6px;
} 
.navbar a:hover {
  color: var(--color-primary-dark);
}
.navbar img{
height: 3.5rem;
} 
.image-container img{
  display: block;
  margin: auto;
}
.image-row {
  display: flex;
  justify-content: center; /* Center the images horizontally */
  flex-wrap: wrap; /* Allow images to wrap to the next line if there's not enough space */
}

.image-item {
  position: relative; /* Position images relative to their container */
  margin: 0; /* Add margin between images */
  width: 10%;
  height: 2%;
}

.image-overlay {
  position: absolute; /* Position the overlay */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 11, 0, 0.649); /* Semi-transparent gray color */
  z-index: 1; /* Ensure the overlay is above the image */
}

.image-item img {
  max-width: 100%; /* Ensure images don't exceed their container's width */
  height: auto; /* Maintain aspect ratio */
  z-index: 0; /* Ensure images are below the overlay */
}

/* footer */
.store-footer__inner{
  background-color: var(--bottom-footer-bg);
}
.store-footer{
  background-color: rgb(243,244,246);
}
.emzuri-img{
  height:5rem;
}
.store-footer__contacts-row{
  border-bottom-width:2px;
  border-top-width:2px;
  border-style:dashed;
  border-bottom-color:rgba(234,236,239);
  border-top-color:rgba(234,236,239);
  background-color:rgb(243,244,246);
  padding-top:1rem;
  padding-bottom:1rem;
}
.gray-border-1px{
  border: 1px solid rgba(146,151,160);
}
.gray-border-2px{
  border: 2px solid rgba(146,151,160);
}
.gray-bg{
  color:rgba(120,126,138);
}
.gray-fill {
  fill:rgba(120,126,138);
}
.footer-bottom{
  background-color: rgb(249, 250, 251);
 }
 .my-ml{
  margin-left: 7px;
 }
.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}
/* blog previews / list */
.blog-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}
.blog-preview:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.blog-preview h2 {
  font-size: 20px;
  color: var(--color-primary);
  margin-bottom: 8px;
}
.blog-preview a{
  text-decoration: none;
}
.blog-details h2{
  font-size: 20px;
  color:var(--store-text-primary);
  margin-bottom: 10px;
}
.blog-details div{
  margin: 20px 0;
}
.blog-details button {
  background: var(--color-primary);
  color: var(--bg-secondary);
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
/* create new blog form */
/* .create {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  
} */

/* styles.css */

.create {
  margin: 0 auto;
  max-width: 600px; /* Adjust max-width as needed */
  padding: 20px;
  background-color: var(--bg-secondary);
  border: 2px solid var(--gray-border);
}

.grid-item {
  margin-bottom: 20px; /* Add margin between items */
}

.create label {
  display: block; /* Ensures the label is displayed as a block element */
  margin-bottom: 8px; /* Add margin between label and input */
  text-align: right;
}

.create input, 
select,
.phoneInput {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid black;
  border-radius: 4px;
  
}
.create input label,
.phoneInput {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid black;
  border-radius: 4px;
  
}
.create select:focus,
.create input:focus {
  border-color: var(--color-primary-dark) !important;
}

.create button {
  border: 1px var(--color-primary-dark) solid;
  background-color: var(--color-primary-dark);
  color: var(--bg-secondary);
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.create button:hover {
  border: 1px var(--color-primary-dark) solid;
  color: var(--color-primary-dark);
  background-color: var(--bg-secondary);
}
.err-msg p{
  font-size: 12px;
  font-weight: lighter;
}
@media screen and (max-width: 600px) {
  .grid-item {
    margin-bottom: 20px; /* Add margin between items on small screens */
  }
}


/* 
.create .input-grid{
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  padding: 10px;
}
.create label {
  text-align: right;
  display: block;
  margin-bottom: 4px;
  color: var(--store-text-primary);
}
.create h2 {
  font-size: 20px;
  color: var(--store-text-primary);
  margin-bottom: 30px;
  text-align: center;
}
.create p{
  text-align: center;
}
.create .last-p{
  margin-bottom: 5%;
}
.create input, .create textarea, .create select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.create .input-grid div{
  text-align: center;
 }
.create button {
  border: 1px var(--color-primary) solid;
  background-color: var(--color-primary);
  color: var(--bg-secondary);
  font-size: 1rem;
  border-radius: 5px;
  padding: 2% 5%;
  align-self: center;
}
.create button:hover {border: 1px var(--color-primary) solid;
  color: var(--color-primary);
  background-color: var(--bg-secondary); */
/* } */
/* Scroll to top button */
.scroll-to-top {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  text-align: center;
  position: fixed;
  bottom: 70px;
  z-index: 9;
  display: none;
  cursor: pointer;
}

.scroll-to-top.left {
  right: auto;
  right: initial;
  left: 30px;
}

.scroll-to-top.right {
  right: 30px;
  left: auto;
  left: initial;
}

.scroll-to-top.center {
  right: calc(50% - 23px);
  left: auto;
  left: initial;
}

.scroll-to-top:after {
  position: absolute;
  font-family: sallaicons;
  content: "\e94b";
  text-align: center;
  line-height: 38px;
  font-size: 20px;
  color: var(--store-text-primary);
  left: 0;
  top: 0;
  width: 46px;
  height: 46px;
  cursor: pointer;
  display: block;
  z-index: -1;
  border-radius: 50%;
  background: var(--bg-secondary);
  border: 4px solid var(--bg-primary);
}

.scroll-to-top path {
  stroke: #414042;
  stroke: var(--color-primary);
  stroke-width: 4;
  box-sizing: border-box;
  fill: none;
}

.scroll-to-top:hover:after {
  background: var(--color-primary);
  color: var(--color-primary-reverse);
}

/* Animations */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
  }
  to {
      opacity: 1;
      transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInLeft {
  from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
  }
  to {
      opacity: 1;
      transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
  }
  to {
      opacity: 1;
      transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
  }
  to {
      opacity: 1;
      transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

.warranty-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 5%;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
  

}
.warranty-details h4 {
  font-size: 1.2em;
  color: var(--color-primary);
}
.warranty-details p {
  text-align: right;
  direction: rtl;
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.warranty-details span {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
input.error{
  border: 1px solid var(--error);
}
.ar{
  direction: rtl;
}